<template>
    <div :id="`svgaAnimation_${id}`" class="svga"></div>
</template>
<script>
// import SVGA from 'svgaplayerweb';

import {getSVGA, clearSVGA} from '@/util/svga.js';

export default {
    name: 'svgaCom',
    props: {
        id: {
            type: [Number, String],
            value: null
        },
        svgaUrl: {
            type: String,
            value: null
        },
        loops: {
            type: [Number, String],
            value: 0
        }
    },
    data() {
        return {
            // player: null,
            // parser: null,
        };
    },
    mounted() {
        this.svgaPlayer(this.svgaUrl, this.loops)
    },
    methods: {
        // 播放svga的方法
        svgaPlayer(url, loops) {
            //一定要使用$nextTick，等到页面加载完成再处理数据，否则会找不到页面元素，报Undefind的错误
            this.$nextTick(async () => {
                try {
                    getSVGA(url,`#svgaAnimation_${this.id}`,loops,this.startFuc,this.endFuc)
                    
                    // this.parser = new SVGA.Parser();
                    // this.parser.load(url, (videoItem) => {
                    //     this.player.loops = loops ? loops : 0 // 播放次数
                    //     this.player.clearsAfterStop = true // 动画结束时，是否清空画布
                    //     this.player.setVideoItem(videoItem);
                    //     this.player.startAnimation();
                    //     this.$emit('start')
                    //     this.player.onFinished(() => {
                    //         console.log('svga完成')
                    //         this.$emit('clear')
                    //         this.player.clear()
                    //         this.player = null
                    //     })
                    // })
                } catch (error) {
                    console.log(error)
                }
            })
        },

        startFuc(){
            this.$emit('start')
        },
        endFuc(){
            this.$emit('clear')
        }
    },
    beforeDestroy() {
        clearSVGA(`#svgaAnimation_${this.id}`)
        // if (this.player) {
        //     this.player.stopAnimation()
        //     this.player = null
        // }
    },
};
</script>
<style lang="less">
.svga {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}
</style>