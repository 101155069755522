const SVGA = require('svgaplayerweb')
import DB from 'svga.lite/db'

var svgaParser = new SVGA.Parser();
var svgaPlayerList = []

//加载过存放db缓存
const db = new DB()

/**
 * 快速加载svga资源，但不装载
 * @param name 资源name
 */
export const loadSVGA = async (name) => {
    let data = ''
    if (db) {
        console.log('--------走db缓存')
        data = await db.find(name)
    }
    if (!data) {
        console.log('------第一次加载')
        data = new Promise((resolve, reject) => {
            svgaParser.load(name, (videoItem) => {
                // 插入数据
                db.insert(name, videoItem)
                resolve(videoItem)
            }, (err) => {
                console.log('svga加载失败：', err)
                reject(err)
            })
        })
    }
    return data
}

/**
 * 快速装载一个svga动画
 * @param fileName 资源name
 * @param className 挂载元素的class name
 * @param loops 播放次数 默认为空，循环播放
 * @param callback 回调
 * @param frame 从第几帧开始。默认0帧
 * @param clearsAfterStop
 */
export const getSVGA = async (fileName, className, loops = 0, startCallback, endCallback, frame = 0, play = true, clearsAfterStop = true) => {
    const videoItem = await loadSVGA(fileName)
    // console.log(videoItem)
    var svga = new SVGA.Player(className);
    svga.loops = loops;
    svga.clearsAfterStop = clearsAfterStop;
    svga.setVideoItem(videoItem);
    svga.stepToFrame(frame, play)
    // 开始
    if (typeof startCallback == "function") {
        svgaPlayerList.push({ className, svga })
        startCallback()
    }

    svga.onFinished(() => {
        console.log('svga完成')
        svga.stopAnimation()
        svga = null
        if (typeof endCallback == "function") {
            endCallback()
        }
    })
    return svga
}

/**
 * 停止一个指定的svga动画
 */
export const clearSVGA = (className) => {
    let index = svgaPlayerList.findIndex(item => item.className == className)
    // console.log(index)
    if (index >= 0) {
        // console.log(svgaPlayerList[index])
        svgaPlayerList[index].svga.stopAnimation()
        svgaPlayerList.splice(index, 1)
    }
    // console.log(svgaPlayerList)
}