<template>
    <div class="box d-flex flex-column flex-1">
        <div class="iphoneBox d-flex a-center j-center" v-if="isIphone">
            <div class="popBox">
                <div class="text">{{ isIphoneText }}</div>
            </div>
        </div>

        <!-- pc端 -->
        <div class="mainPC flex-1" style="width: 1200px;" v-if="!isLoading && !isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/202406/bg_PC_activity.jpg" alt="">
            <div class="content">
                <div class="scrollView">
                    <div class="payList">
                        <div class="item d-flex a-center" v-for="(item, index) in payList" :key="index">
                            <div class="left d-flex flex-wrap" :class="{ filter10: !item.isLock }">
                                <div class="giveGoods d-flex flex-column a-center j-center"
                                    v-for="(goods, gIndex) in item.items" :key="gIndex">
                                    <svgaCom class="imgUrl" v-if="goods.svgaUrl" :loop="1" :svgaUrl="goods.svgaUrl"
                                        :id="`activitySvga_${index}_${gIndex}`" />
                                    <img class="imgUrl" v-else
                                        :src="goods.iconUrl + '?x-oss-process=image/resize,w_100,m_lfit'" alt="">
                                    <div class="goodsName">{{ goods.name }}</div>
                                    <div class="dayText" v-if="goods.days">{{ goods.days }}天</div>
                                </div>
                            </div>
                            <div class="descText" v-if="index == 0">*骑士以下直升、骑士以上用户3000贵族经验</div>
                            <div class="moneyBox d-flex flex-column a-center j-center flex-1"
                                :class="{ filter10: !item.isLock }">
                                <div class="title">累充{{ item.money }}元</div>
                                <div class="progressBar">
                                    <div class="active"
                                        :style="{ width: item.payMoney * 100 / item.money > 100 ? '100%' : item.payMoney * 100 / item.money + '%' }">
                                    </div>
                                </div>
                                <div class="payMoney">{{ item.payMoney }}/{{ item.money }}元</div>
                            </div>
                            <div @click="btnHandle(item)"
                                :class="['btn', { filter10: !item.isLock }, { btn0: item.status == 0 }, { btn1: item.status == 1 }, { btn2: item.status == 2 }]">
                            </div>
                            <div class="mask d-flex flex-column a-center j-center" v-if="!item.isLock">
                                <img src="../../assets/imgs/activity/icon_lock.png" alt="" class="icon_lock">
                            </div>
                        </div>
                    </div>

                    <div class="cardBox">
                        <img id="equity" ref="equity" src="../../assets/imgs/activity/icon_PC_equityTitle.png" alt=""
                            class="title">
                        <img class="bg_equity" src="../../assets/imgs/activity/bg_equity.png" alt="">
                    </div>

                    <div class="cardBox">
                        <img id="rule" ref="rule" src="../../assets/imgs/activity/icon_PC_ruleTitle.png" alt=""
                            class="title">
                        <div class="ruleBox">
                            <p>1. 活动期间充值达到相对应档位金额即可成功领取该档对应奖励；</p>
                            <p>2. 充值活动时效期内累积，每档只能领取一次；</p>
                            <p>3. 充值奖励显示5档，每达到一档后往后解锁新的一档；</p>
                            <p>4. 所有充值都计入充值累积，随心币兑换随心钻不计入充值累积；</p>
                            <p>5. 活动自选套装，奖励的靓号，定制头像框，定制气泡，点击领取后联系客服方可兑换，靓号可以自用及赠送，领取有效期为活动结束日后20天内。</p>
                        </div>
                    </div>

                    <div class="footerDesc">
                        <p>在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；</p>
                        <p>如有疑问请联系在线客服或客服QQ: 1058127957进行咨询</p>
                    </div>

                </div>
                <div class="equityBtn" @click="scrollChange('equity')">
                    <img class="icon" src="../../assets/imgs/activity/202406/icon_equity.png" alt="">
                </div>
                <div class="ruleBtn" @click="scrollChange('rule')">
                    <img class="icon" src="../../assets/imgs/activity/202406/icon_rule.png" alt="">
                </div>
            </div>
            <div class="goHomeBtn" @click="openHomeHandle">
                <img src="../../assets/imgs/activity/icon_goHome.png" alt="" class="icon_goHome">
            </div>

            <div class="receiveSuccessBox" v-if="isShowReceiveSuccess">
                <div class="mask"></div>
                <img class="bg_receiveSuccess" src="../../assets/imgs/activity/202406/bg_PC_receiveSuccess.png" alt="">
                <div class="receiveList d-flex flex-wrap">
                    <div class="receiveItem d-flex flex-column a-center j-center" v-for="(item, index) in receiveList"
                        :key="item.id">
                        <svgaCom class="imgUrl" v-if="item.svgaUrl" :loop="1" :svgaUrl="item.svgaUrl"
                            :id="`receiveSvga_${index}`" />
                        <img class="imgUrl" v-else :src="item.iconUrl + '?x-oss-process=image/resize,w_100,m_lfit'" alt="">
                        <div class="goodsName">{{ item.name }}</div>
                        <div class="dayText" v-if="item.days">{{ item.days }}天</div>
                    </div>
                </div>
                <div class="btnConfirm" @click="closeReceiveSuccess"></div>
            </div>

            <div class="noActivityBox d-flex a-center j-center" v-if="isNoActivity">
                <div class="mask"></div>
                <div class="popBox">
                    <div class="d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/icon_shalou.png" alt="" class="icon_shalou">
                        <div class="text">{{ noActivityMessage }}</div>
                    </div>
                    <div class="btnConfirm" @click="isNoActivity = !isNoActivity"></div>
                </div>
            </div>
        </div>

        <!-- 移动端 -->
        <div class="main" v-if="!isLoading && isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/202406/bg_H5_activity.png" alt="">
            <div class="scrollView">
                <div class="payList">
                    <div class="item" v-for="(item, index) in payList" :key="index">
                        <div class="top d-flex a-center j-sb" :class="{ filter10: !item.isLock }">
                            <div class="label">累充{{ item.money }}元</div>
                            <div class="progressBar d-flex a-center j-center">
                                <div class="active"
                                    :style="{ width: item.payMoney * 100 / item.money > 100 ? '100%' : item.payMoney * 100 / item.money + '%' }">
                                </div>
                                <div class="text">{{ item.payMoney }}/{{ item.money }}元</div>
                            </div>
                        </div>

                        <div class="goodsList d-flex a-center j-center flex-wrap" :class="{ filter10: !item.isLock }">
                            <div class="goods d-flex flex-column a-center j-center"
                                :style="(gIndex + 1) % 4 == 0 ? 'margin-right:0' : ''" v-for="(goods, gIndex) in item.items"
                                :key="gIndex">
                                <svgaCom class="imgUrl" v-if="goods.svgaUrl" :svgaUrl="goods.svgaUrl"
                                    :id="`activitySvga_${index}_${gIndex}`" />
                                <img class="imgUrl" v-else :src="goods.iconUrl + '?x-oss-process=image/resize,w_100,m_lfit'"
                                    alt="">
                                <div class="goodsName">{{ goods.name }}</div>
                                <div class="dayText" v-if="goods.days">{{ goods.days }}天</div>
                            </div>
                        </div>

                        <div @click="btnHandle(item)"
                            :class="['btn', { filter10: !item.isLock }, { btn0: item.status == 0 }, { btn1: item.status == 1 }, { btn2: item.status == 2 }]">
                        </div>
                        <div class="mask d-flex flex-column a-center j-center" v-if="!item.isLock">
                            <img src="../../assets/imgs/activity/icon_lock.png" alt="" class="icon_lock">
                        </div>

                        <div class="descText" v-if="index == 0">*骑士以下直升、骑士以上用户3000贵族经验</div>
                    </div>
                </div>

                <div class="cardBox">
                    <img id="equity" src="../../assets/imgs/activity/icon_equityTitle.png" alt="" class="title">
                    <div class="equityBox d-flex">
                        <img src="../../assets/imgs/activity/bg_H5_equityLeft.png" alt="" class="iconLeft">
                        <div class="rightBox">
                            <img src="../../assets/imgs/activity/bg_H5_equityRight.png" alt="" class="iconRight">
                        </div>
                    </div>
                </div>

                <div class="cardBox">
                    <img id="rule" src="../../assets/imgs/activity/icon_ruleTitle.png" alt="" class="title">
                    <div class="ruleBox">
                        <p>1. 活动期间充值达到相对应档位金额即可成功领取该档对应奖励；</p>
                        <p>2. 充值活动时效期内累积，每档只能领取一次；</p>
                        <p>3. 充值奖励显示5档，每达到一档后往后解锁新的一档；</p>
                        <p>4. 所有充值都计入充值累积，随心币兑换随心钻不计入充值累积；</p>
                        <p>5. 活动自选套装，奖励的靓号，定制头像框，定制气泡，点击领取后联系客服方可兑换，靓号可以自用及赠送，领取有效期为活动结束日后20天内。</p>
                    </div>
                </div>

                <div class="footerDesc">
                    <p>在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；</p>
                    <p>如有疑问请联系在线客服或客服QQ: 1058127957进行咨询</p>
                </div>

            </div>

            <div class="receiveSuccessBox d-flex flex-column a-center j-center" v-if="isShowReceiveSuccess" @click.stop="">
                <div class="topBox">
                    <img class="bg_receiveSuccess" src="../../assets/imgs/activity/202406/bg_H5_receiveSuccess.png" alt="">
                    <div class="receiveList d-flex flex-wrap a-center j-center">
                        <div class="receiveItem d-flex flex-column a-center j-center" v-for="(item, index) in receiveList"
                            :key="item.id">
                            <svgaCom class="imgUrl" v-if="item.svgaUrl" :loop="1" :svgaUrl="item.svgaUrl"
                                :id="`receiveSvga_${index}`" />
                            <img class="imgUrl" v-else :src="item.iconUrl + '?x-oss-process=image/resize,w_100,m_lfit'"
                                alt="">
                            <div class="goodsName">{{ item.name }}</div>
                            <div class="dayText" v-if="item.days">{{ item.days }}天</div>
                        </div>
                    </div>
                </div>

                <div class="btnConfirm" @click="closeReceiveSuccess"></div>
            </div>

            <div class="noActivityBox d-flex a-center j-center" v-if="isNoActivity">
                <div class="popBox">
                    <div class="d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/icon_shalou.png" alt="" class="icon_shalou">
                        <div class="text">{{ noActivityMessage }}</div>
                    </div>
                    <div class="btnConfirm" @click="isNoActivity = !isNoActivity"></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import svgaCom from '@/components/svgaCom.vue'
import {
    getPayItemList,
    receive,
} from "@/util/api/api.js"
export default {
    components: {
        svgaCom,
    },
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            isNoActivity: false,// 活动是否存在弹窗
            noActivityMessage: '',// 没有活动提示文案
            payList: [],// 活动充值列表
            isShowReceiveSuccess: false,// 是否展示领取成功弹窗
            receiveList: [],// 当前领取的商品列表

            isIphone: false,
            isIphoneText: '',
        }

    },
    async created() {
        if (this.$route.params && this.$route.params.id) {
            this.activityId = this.$route.params.id
        }
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }

        // // 如果是iphone则提示不可用引导弹窗
        // if (/iPhone/i.test(userAgent)) {
        //     this.isIphone = true
        //     this.isIphoneText = "IOS用户请去随心语音官网www.gxmuchun.com参与本活动"
        //     return
        // }

        await this._getPayItemList()
    },
    methods: {
        // 滚动到锚点位置
        scrollChange(type) {
            this.$refs[type].scrollIntoView({ behavior: 'smooth' });
        },

        // 进入官网
        openHomeHandle() {
            if (this.$route.path == '/') {
                // 刷新首页
                location.reload()
            } else {
                // 打开首页
                let routeData = this.$router.resolve('/');
                window.open(routeData.href, '_blank');
            }
        },

        // 获取活动详情
        async _getPayItemList() {
            try {
                let res = await getPayItemList({
                    id: this.activityId
                })
                if (res.resultCode == '000000') {
                    console.log(res)
                    if (res.data && res.data.list) {
                        this.payList = res.data.list
                        document.title = res.data.name;
                        this.isLoading = false
                    }
                } else if (res.resultCode == '900001' || res.resultCode == '900002' || res.resultCode == '900003') {
                    this.isNoActivity = true
                    this.noActivityMessage = res.errorMessage
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        },

        // 按钮事件
        btnHandle(item) {
            console.log(item)
            // 未充值
            if (item.status == 0) {
                if (this.isMobile) {
                    appPostStatus.postMessage("我要跳转到你app这里");
                } else {
                    let routeData = this.$router.resolve('/recharge');
                    window.open(routeData.href, '_blank');
                }

            } else if (item.status == 2) {
                // 未领取
                this.receiveList = []
                if (item.items.length > 0) {
                    item.items.forEach(it => this.receiveList.push(it))
                }
                this._receive(item.id)
            } else {
                // 已领取
                return
            }
        },

        /**
         * 活动领取
         * 900001:当前不在活动时间
         * 900002:已经领取过
         * 900003:活动已经超过20天，不能领取
         * @param {*} id 
         */
        async _receive(id) {
            // 开启领取loading
            let loading = this.$loading({
                lock: true,
                text: '领取中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            try {
                let params = {
                    activityPayId: this.activityId,
                    activityPayItemId: id,
                }
                let res = await receive(params)
                if (res.resultCode == '000000') {
                    console.log(res)
                    loading.close()
                    this.isShowReceiveSuccess = true
                    await this._getPayItemList()
                } else if (res.resultCode == '900001' || res.resultCode == '900002' || res.resultCode == '900003') {
                    this.isNoActivity = true
                    this.noActivityMessage = res.errorMessage
                }
                loading.close()
            } catch (error) {
                console.log(error)
                loading.close()
                await this._getPayItemList()
            }
        },

        // 关闭领取成功弹窗
        closeReceiveSuccess() {
            this.isShowReceiveSuccess = false
        }
    },
}
</script>

<style lang="less" scoped>
// 页面背景颜色
@boxBgColor: #CDC3F0;
// 卡片背景颜色
@cardBgColor: #8479CA;
// 商品背景颜色
@goodsBgColor: #6A53BC;
// 天数字体颜色
@dayTextColor: #CE5221;

.box {
    color: #fff;
    background: @boxBgColor;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .goHomeBtn {
            height: 40px;
            width: 140px;
            position: absolute;
            top: 55px;
            right: -148px;
            cursor: pointer;

            .icon_goHome {
                height: 100%;
                width: 100%;
                display: block;
            }
        }

        .bg_activity {
            height: 1080px;
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .content {
            height: 840px;
            width: 860px;
            padding: 48px 20px 30px 20px;
            position: absolute;
            top: 240px;
            right: 0px;
            box-sizing: border-box;

            .scrollView {
                overflow-y: auto;
                overflow-x: hidden;
                height: 760px;
                padding: 0 20px;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 12px;
                    background: #8479CA;
                    border-radius: 8px;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 12px;
                    background: #FFFFFF;
                    border-radius: 8px;
                }

                .payList {
                    .item {
                        width: 760px;
                        padding: 30px;
                        box-sizing: border-box;
                        background: @cardBgColor;
                        border-radius: 24px;
                        margin-bottom: 32px;
                        position: relative;

                        .mask {
                            width: 760px;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.4);
                            border-radius: 24px;
                            position: absolute;
                            left: 0;
                            top: 0;

                            .icon_lock {
                                height: 96px;
                                width: 72px;
                                display: block;
                            }
                        }

                        .left {
                            width: 400px;
                            position: relative;

                            .giveGoods {
                                position: relative;
                                width: 90px;
                                height: 90px;
                                background: @goodsBgColor;
                                border-radius: 8px;
                                border: 1px solid #A99ADE;
                                margin-right: 10px;
                                margin-bottom: 10px;
                                box-sizing: border-box;

                                .imgUrl {
                                    height: 60px;
                                    width: 60px;
                                    display: block;
                                    object-fit: contain;
                                }

                                .goodsName {
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #FEFFB4;
                                    text-align: center;
                                }

                                .dayText {
                                    position: absolute;
                                    top: -2px;
                                    right: -2px;
                                    padding: 0 5px;
                                    height: 16px;
                                    line-height: 16px;
                                    background: #FFD900;
                                    border-radius: 0px 8px 0px 8px;
                                    font-size: 12px;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: @dayTextColor;
                                }
                            }
                        }

                        .descText {
                            position: absolute;
                            left: 32px;
                            bottom: 14px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FEFFB4;

                            margin-top: 0;
                            text-align: left;
                            padding-bottom: 0;
                        }

                        .moneyBox {
                            text-align: center;

                            .title {
                                font-size: 24px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFD900;
                            }

                            .progressBar {
                                width: 120px;
                                height: 12px;
                                background: #580011;
                                border-radius: 8px;
                                position: relative;
                                margin: 16px 0 10px;
                                overflow: hidden;

                                .active {
                                    background: #FFD900;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    height: 12px;
                                    width: 0;
                                    // border-radius: 8px;
                                }
                            }

                            .payMoney {
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FEFFB4;
                            }
                        }

                        .btn {
                            width: 128px;
                            height: 53px;
                            background: rgba(255, 217, 0, 0.5);
                            border-radius: 12px;
                            cursor: pointer;

                            &.btn0 {
                                background: url('../../assets/imgs/activity/icon_recharge.png')no-repeat center/cover;
                            }

                            &.btn1 {
                                background: url('../../assets/imgs/activity/icon_received.png')no-repeat center/cover;
                            }

                            &.btn2 {
                                background: url('../../assets/imgs/activity/icon_receive.png')no-repeat center/cover;
                            }
                        }
                    }
                }

                .cardBox {

                    .title {
                        height: 33px;
                        width: 638px;
                        display: block;
                        margin: 48px auto 32px;
                    }

                    .bg_equity {
                        width: 760px;
                        height: 527px;
                        background: #231A25;
                        border-radius: 24px;
                    }

                    .ruleBox {
                        width: 760px;
                        height: 220px;
                        background: @cardBgColor;
                        border-radius: 24px;
                        padding: 39px 48px 31px;
                        box-sizing: border-box;

                        p {
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFF8CA;
                        }
                    }
                }

                .footerDesc {
                    margin-top: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FEFFB4;
                    text-align: center;
                    padding-bottom: 50px;
                }
            }

            .equityBtn {
                position: absolute;
                top: 0;
                right: -145px;
                height: 115px;
                width: 123px;
                cursor: pointer;

                .icon {
                    height: 115px;
                    width: 123px;
                    display: block;
                }
            }

            .ruleBtn {
                position: absolute;
                top: 130px;
                right: -145px;
                height: 115px;
                width: 123px;
                cursor: pointer;

                .icon {
                    height: 115px;
                    width: 123px;
                    display: block;
                }
            }
        }

        .receiveSuccessBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .bg_receiveSuccess {
                height: 577px;
                width: 1920px;
                display: block;
                position: absolute;
                left: 50%;
                top: 117px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }

            .receiveList {
                padding-top: 280px;
                width: 400px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 117px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .receiveItem {
                    position: relative;
                    width: 90px;
                    height: 90px;
                    background: @goodsBgColor;
                    border-radius: 8px;
                    border: 1px solid #A99ADE;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    box-sizing: border-box;

                    .imgUrl {
                        height: 60px;
                        width: 60px;
                        display: block;
                        object-fit: contain;
                    }

                    .goodsName {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FEFFB4;
                        text-align: center;
                    }

                    .dayText {
                        position: absolute;
                        top: -2px;
                        right: -2px;
                        padding: 0 5px;
                        height: 16px;
                        line-height: 16px;
                        background: #FFD900;
                        border-radius: 0px 8px 0px 8px;
                        font-size: 12px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: @dayTextColor;
                    }
                }
            }


            .btnConfirm {
                height: 56px;
                width: 140px;
                background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                position: absolute;
                left: 50%;
                top: 755px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                cursor: pointer;
            }
        }

        .noActivityBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .popBox {
                padding-top: 35px;
                width: 405px;
                height: 191px;
                background: #463B50;
                border-radius: 23px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 467px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 50px;
                    width: 40px;
                    display: block;
                    margin-right: 18px;
                }

                .text {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 42px;
                    width: 102px;
                    background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 35px auto 0;
                    cursor: pointer;
                }
            }

        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 100vw;
            display: block;
        }

        .scrollView {
            .payList {
                .item {
                    width: 88vw;
                    background: @cardBgColor;
                    border-radius: 6.4vw;
                    margin: 3.2vw auto;
                    padding-bottom: 3.2vw;
                    position: relative;

                    .top {
                        padding: 3.733vw;

                        .label {
                            font-size: 5.333vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFD900;
                        }

                        .progressBar {
                            width: 32vw;
                            height: 5.867vw;
                            background: #6A53BC;
                            border-radius: 2.933vw;
                            position: relative;
                            overflow: hidden;

                            .active {
                                width: 0;
                                height: 5.867vw;
                                background: #CDC3F0;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }

                            .text {
                                font-size: 3.2vw;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                text-align: center;
                                position: relative;
                                z-index: 2;
                            }
                        }
                    }

                    .goodsList {
                        .goods {
                            position: relative;
                            width: 18.667vw;
                            height: 18.667vw;
                            background: @goodsBgColor;
                            border-radius: 2.133vw;
                            border: 0.267vw solid #A99ADE;
                            margin-right: 2.133vw;
                            margin-bottom: 2.133vw;

                            .imgUrl {
                                width: 12.267vw;
                                height: 12.267vw;
                                display: block;
                                object-fit: contain;
                            }

                            .goodsName {
                                font-size: 2.667vw;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FEFFB4;
                            }

                            .dayText {
                                height: 4.267vw;
                                line-height: 4.267vw;
                                padding: 0 0.8vw;
                                background: #FFD900;
                                border-radius: 0px 2.133vw 0px 2.133vw;
                                font-size: 2.667vw;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: @dayTextColor;
                                position: absolute;
                                top: -0.4vw;
                                right: -0.4vw;
                            }
                        }
                    }

                    .btn {
                        height: 11.2vw;
                        width: 27.2vw;
                        margin: 3.2vw auto;
                        cursor: pointer;

                        &.btn0 {
                            background: url('../../assets/imgs/activity/icon_recharge.png')no-repeat center/cover;
                        }

                        &.btn1 {
                            background: url('../../assets/imgs/activity/icon_received.png')no-repeat center/cover;
                        }

                        &.btn2 {
                            background: url('../../assets/imgs/activity/icon_receive.png')no-repeat center/cover;
                        }
                    }

                    .descText {
                        text-align: center;
                        font-size: 2.667vw;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FEFFB4;
                    }

                    .mask {
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.4);
                        border-radius: 6.4vw;
                        position: absolute;
                        left: 0;
                        top: 0;

                        .icon_lock {
                            height: 25.6vw;
                            width: 19.2vw;
                            display: block;
                        }
                    }
                }
            }

            .cardBox {
                padding-top: 12.8vw;

                .title {
                    height: 6.4vw;
                    width: 89.867vw;
                    display: block;
                    margin: 0 auto 4.267vw;
                }

                .equityBox {
                    height: 165.867vw;
                    width: 88vw;
                    margin: 0 auto;
                    border-radius: 6.4vw;
                    background: #231A25;
                    overflow: hidden;

                    .iconLeft {
                        height: 165.867vw;
                        width: 16.8vw;
                        display: block;
                        z-index: 1;
                    }

                    .rightBox {
                        width: 71.2vw;
                        height: 165.867vw;
                        overflow-x: auto;

                        .iconRight {
                            display: block;
                            height: 100%;
                        }
                    }
                }

                .ruleBox {
                    width: 87.733vw;
                    margin: 0 auto;
                    border-radius: 6.4vw;
                    background: @cardBgColor;
                    padding: 6.667vw;
                    box-sizing: border-box;

                    p {
                        font-size: 4.267vw;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFF8CA;
                    }
                }
            }

            .footerDesc {
                padding: 6.4vw 0 10.667vw;
                margin: 0 auto;
                width: 87.733vw;
                font-size: 3.733vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FEFFB4;

            }
        }

        .receiveSuccessBox {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: 2;
            background: rgba(0, 0, 0, 0.5);

            .topBox {
                position: relative;
                height: 81.6vw;
                width: 100vw;

                .bg_receiveSuccess {
                    height: 81.6vw;
                    width: 100vw;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .receiveList {
                    width: 100vw;
                    height: 40.267vw;
                    padding-right: 6vw;
                    padding-left: 8.133vw;
                    padding-top: 2.133vw;
                    margin-top: 30vw;
                    box-sizing: border-box;

                    .receiveItem {
                        position: relative;
                        width: 18.667vw;
                        height: 18.667vw;
                        background: @goodsBgColor;
                        border-radius: 2.133vw;
                        border: 0.267vw solid #A99ADE;
                        margin-right: 2.133vw;
                        margin-bottom: 2.133vw;

                        .imgUrl {
                            width: 12.267vw;
                            height: 12.267vw;
                            display: block;
                            object-fit: contain;
                        }

                        .goodsName {
                            font-size: 2.667vw;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FEFFB4;
                        }

                        .dayText {
                            height: 4.267vw;
                            line-height: 4.267vw;
                            padding: 0 0.8vw;
                            background: #FFD900;
                            border-radius: 0px 2.133vw 0px 2.133vw;
                            font-size: 2.667vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: @dayTextColor;
                            position: absolute;
                            top: -0.4vw;
                            right: -0.4vw;
                        }
                    }
                }
            }




            .btnConfirm {
                height: 11.2vw;
                width: 27.2vw;
                background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                margin-top: 8vw;
                cursor: pointer;
            }
        }

        .noActivityBox {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: 2;
            background: rgba(0, 0, 0, 0.5);

            .popBox {
                padding-top: 5.067vw;
                width: 70.4vw;
                height: 42.933vw;
                background: #463B50;
                border-radius: 4.267vw;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 40%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 13.333vw;
                    width: 10.667vw;
                    display: block;
                    margin-right: 4.267vw;
                }

                .text {
                    font-size: 3.733vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 11.2vw;
                    width: 27.2vw;
                    background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 8vw auto 0;
                    cursor: pointer;
                }
            }

        }
    }

    .iphoneBox {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 2;
        background: rgba(0, 0, 0, 0.5);

        .popBox {
            padding: 5.067vw;
            width: 70.4vw;
            // height: 42.933vw;
            background: #463B50;
            border-radius: 4.267vw;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            top: 40%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: 1;

            .text {
                font-size: 3.733vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
            }
        }

    }

    .filter10 {
        filter: blur(10px);
    }
}
</style>
